<template>
  <div :class="className">
    <van-nav-bar
      title="应急管理"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in alarmRecordsConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName, query: item.routerQuery || {} }"
        :border="idx !== alarmRecordsConf.length - 1"
      >
        <template #title>
          <img class="title-img" :src="item.icon" />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

import yingjitongxun from "../../assets/images/yingjitongxun.png";
import yingjiyuan from "../../assets/images/yingjiyuan.png";
export default {
  name: "Urgent",
  data() {
    return {
      className: "urgent-manage",
      alarmRecordsConf: [
        {
          icon: yingjitongxun,
          iconColor: "#FC4935",
          title: "应急通讯",
          routerName: "Communication",
          router: "communication"
        },
        {
          icon: yingjiyuan,
          iconColor: "#23A86F",
          title: "应急预案",
          routerName: "Plan",
          router: "plan"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      authRoutes: state => state.menu.roles
    })
  },
  created() {
    this.initAlarmRecordsConf();
  },
  methods: {
    initAlarmRecordsConf() {
      // console.log(this.alarmRecordsConf, this.authRoutes)
      // this.alarmRecordsConf = this.alarmRecordsConf.filter(v =>
      //   this.authRoutes.includes(v.router)
      // );
    }
  }
};
</script>

<style lang="scss">
.urgent-manage {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    span {
      color: #333333;
      font-size: 15px;
    }
  }
}
.title-img {
  width: 18px;
  margin-right: 8px;
  position: relative;
  top: 5px;
}
</style>
